<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.bankAccount')" filterable :clearable="clearable"
      :size="size" class="w-100">
      <el-option v-for="(bankAccount,index) in bankAccounts" :key="'bankAccounts-' + index" :label="bankAccount.name" :value="bankAccount.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      clearable:{
        default: true
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id;
            if(this.id && this.bankAccounts.length > 0 && this.bankAccounts.some(acc => acc.id === this.id)){
              let account = this.bankAccounts.find(acc => acc.id === this.id);
              this.$emit('getAccount', account)
            }
            
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.bankAccounts && this.bankAccounts.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        bankAccounts: 'bankAccounts/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'bankAccounts/inventory'
      }),
      dispatch(e) {
        let account = this.bankAccounts.find(acc => acc.id === e);
        this.$emit('getAccount', account)
        this.$emit('input', e)
        this.selected = e;
      }
    },
  }

</script>
